@import "~@angular/material/prebuilt-themes/indigo-pink.css";
.fs-12 {
    font-size: 12px;
}
.error, .errorForm{
    color:red;
    font-size: 14px;
    margin: 10px 0;
}
.success, .successForm{
    color:lightgreen;
    font-size: 14px;
    margin: 10px 0;
}
.errorForm, .successForm{
    display: block;
    margin:10px 12px;
    text-align: right;
    width:100%;
}
.alert{
    position: fixed;
    top: 63px;
    right: 30px;
    z-index: 9999;
}
.card{
    box-shadow: 2px 0px 5px 0 rgba(0, 0, 0, 0.35);
}
button, a{
    cursor: pointer;
}
button:focus, input:focus{
    outline:none;
    box-shadow: none;
}
.padding20{
    padding: 0 20px 20px;
    @media screen and (max-width:767px){
        padding: 0;
    }
}
.padding10{
    padding: 0 10px 10px;
    @media screen and (max-width:767px){
        padding: 0;
    }
}
.mat-progress-bar{
  height: 5px !important;
  bottom: 0px;
  position: absolute !important;
}
.mat-progress-bar-fill::after{
    background-color: #00adef!important;
}
.card-header{
    font-size: 20px;
  font-family: markSemiBold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: 1.2px;
  text-align: left;
  color: #323232;
  background: #fff;
    border-bottom:1px solid #ccc;
}
thead{
    border-bottom: 1px solid #ccc;
}
.card-body{
    padding:10px 25px;
    @media screen and (max-width:767px){
        padding:10px;
    }
}
.align-right{
    text-align: right;
}
.card-body.table-responsive{
    padding:0;
}
.card-body.table-responsive thead th{
    border-bottom: none;
    font-size: 15.5px;
}
.card-body.table-responsive tbody td{
    font-size: 15px;
    @media screen and (max-width:767px) {
        padding: 10px 3px;
    }
}
.card-body.table-responsive thead th:first-child, .card-body.table-responsive tbody td:first-child{
    padding-left:40px;
    @media screen and (max-width:767px) {
        padding-left:8px;
    }
}
.card-body.table-responsive i{
    cursor: pointer;
}
.add-btn, .right{
    float: right;
}
.componentHeading{
    padding-left: 25px;
}
.form-control:focus, .form-control.is-invalid:focus{
    border: none;
    box-shadow: none;
}
.full-width{
    width:100%;
}
.primary-btn{
    color: #00adef;
    background: #fff;
    border-radius: 23.5px;
    text-transform: uppercase;
    border:1px solid #00adef;
    width: auto;
    font-size: 17px;
    height: 40px;
    line-height: 40px;
    padding: 0 25px;
    @media screen and (max-width:767px){
        font-size: 15px;
        height: 40px;
        padding: 0 20px;
    }
}
.primary-btn[disabled]{
    color: #999;
    border: 1px solid #999;
}
.save-btn{
    border-radius: 23.5px;
    border: none;
    color: #fff;
    height: 40px;
    background: #3ab54a;
    font-family: markSemiBold;
    font-size:17px;
    line-height: 40px;
    text-transform: uppercase;
    padding: 0 25px;
    @media screen and (max-width:767px){
        font-size: 15px;
        height: 40px;
        padding: 0 20px;
    }
}
.action-btn{
    border-radius: 23.5px;
    border: none;
    color: #fff;
    height: 40px;
    background: #251e7c;
    font-family: markSemiBold;
    font-size:17px;
    line-height: 40px;
    text-transform: uppercase;
    padding: 0 25px;
    @media screen and (max-width:767px){
        font-size: 15px;
        height: 40px;
        padding: 0 20px;
    }
}
.adminAction-btn{
    border-radius: 23.5px;
    border: none;
    color: #fff;
    height: 40px;
    background:  #ed1c24 !important;
    font-family: markSemiBold;
    font-size:17px;
    line-height: 40px;
    text-transform: uppercase;
    padding: 0 25px;
    @media screen and (max-width:767px){
        font-size: 15px;
        height: 40px;
        padding: 0 20px;
    }
}
.delete-btn{
   background: #ed1c24 !important;
}
.save-btn[disabled]{
    color: #ccc;
}
.rightFlex{
    margin-left:auto;
}
.table a{
    cursor: pointer;
    color: #00adef!important;
}
.form-control:disabled, .form-control[readonly]{
    background-color: transparent;
}
.paginatorCard {
    margin-bottom: 15px;
.card-body{
    padding: 0 25px;
}
}
.material-icons.pointer{
    cursor: pointer;
}
.overflowHidden{
    overflow: hidden;
}
.align-center{
    text-align: center;
}
.hideMob{
    display: block;
    @media screen and (max-width:767px){
        display: none;
    }
}
.hideDesk{
    display: none;
    @media screen and (max-width:767px){
        display: block;
    }
}
::ng-deep .mat-primary .mat-pseudo-checkbox-checked{
    background: #00adef !important;
}
::ng-deep .mat-primary .mat-option.mat-selected:not(.mat-option-disabled){
    color: #00adef !important;
}
::ng-deep .mat-primary .mat-pseudo-checkbox-checked{
    background: #00adef !important;
}
::ng-deep .mat-tab-group.mat-primary .mat-ink-bar, ::ng-deep  .mat-tab-nav-bar.mat-primary .mat-ink-bar{
    background: #00adef !important;
}
.childOptions{
    padding-left: 30px!important;
}
.parent{
    padding-left: 10px!important;
    font-weight: bold;
}
.noData{
    margin-left: 40px;
    margin-top:10px;
}
.apiError{
    background: #dd2c00;
}
.mat-simple-snackbar-action button {
    color: #fff;
}
.apiSuccess{
    background: #3ab54a;
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: #ebebeb!important;
}
.table-striped tbody tr:nth-of-type(even) {
    background-color: #fafafa!important;
}