/* You can add global styles to this file, and also import other style files */
@import "bootstrap/bootstrap";
@import "spinner";
@import "utils";
@import "rtl";
@import "responsive";
@font-face {
    font-family: mark;
    src:url(../assets/fonts/MarkSimonson-Regular-webfont.ttf) format("truetype");
}
@font-face {
    font-family: markBlack;
    src:url(../assets/fonts/MarkSimonson-AltBlack-webfont.ttf) format("truetype");
}
@font-face {
    font-family: markBold;
    src:url(../assets/fonts/MarkSimonson-AltBold-webfont.ttf) format("truetype");
}
@font-face {
    font-family: markCondensedSemiBold;
    src:url(../assets/fonts/MarkSimonson-AltCondensedSemibold-webfont.ttf) format("truetype");
}
@font-face {
    font-family: markScThin;
    src:url(../assets/fonts/MarkSimonson-ScOsfThin-webfont.ttf) format("truetype");
}
@font-face {
    font-family: markSemiBold;
    src:url(../assets/fonts/MarkSimonson-Semibold-webfont.ttf) format("truetype");
}
@font-face {
    font-family: markThin;
    src:url(../assets/fonts/MarkSimonson-Thin-webfont.ttf) format("truetype");
}


* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'mark';
  }
@media print {
    .breadcrumb {
        display: none !important;
    }
}
